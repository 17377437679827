<template>
  <div class="container-fluid mt-3 mb-3">
    <CRow class="pb-3 pr-3">
      <CCol>
        <h4 class="heading-title">Scan & Store Meter</h4>
      </CCol>
    </CRow>

    <CCard>
      <CRow class="pl-3 pr-3">
        <CCol>
          <div v-if="serverErrors.length">
            <CAlert
              v-model="showDismissibleAlert"
              color="danger"
              closeButton
              v-for="err in serverErrors"
              :key="err"
            >
              {{ err }}
            </CAlert>
          </div>

          <div class="step-wrapper" v-if="step === 1">
            <form v-on:submit.prevent="checkStep">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Batch No.</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="meter.batch_no"
                      placeholder="Enter Batch No."
                      required
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Category</label>
                    <select
                      class="form-control"
                      v-model="meter.category_id"
                      required
                    >
                      <option value="">Select Category</option>
                      <option v-bind:value="1">Water Meter</option>
                    </select>
                  </div>
                </div>
              </div>
              <router-link class="btn btn-info" to="/inventory/meters"
                >Back</router-link
              >
              &nbsp;&nbsp;
              <button type="submit" class="btn btn-primary">Next</button>
            </form>
          </div>

          <div class="step-wrapper" v-if="step === 2">
            <form v-on:submit.prevent="checkStep">
              <div class="row">
                <!-- <div class="col-md-4">
                <div class="form-group">
                  <label>Meter Number</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="meter.meter_number"
                    placeholder="Enter meter number"
                    required
                  />
                </div>
              </div>               -->

                <div class="col-md-4">
                  <div class="form-group">
                    <label>Brand Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="meter.brand_name"
                      placeholder="Enter Brand Name"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Model Number</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="meter.model_number"
                      placeholder="Model number"
                      required
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>Manufacture Year</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="meter.manufacture_year"
                      placeholder="Manufacture Year"
                      required
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>Expire Date</label>
                    <!-- <input
                    type="date"
                    class="form-control"
                    v-model="meter.expiry_date"
                    placeholder="Expire Date"
                  /> -->
                    <input
                      wrapper-class="expire-date-picker-wrapper"
                      input-class="expire-date-picker"
                      class="form-control"
                      type="date"
                      v-model="meter.expiry_date"
                      name="expiry_date"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>IP Standard</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="meter.ip_standard_code"
                      placeholder="IP Standard"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>Max Pressure</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="meter.max_pressure"
                      placeholder="Max Pressure"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>Radio Frequency</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="meter.radio_frequency"
                      placeholder="Radio Frequency"
                    />
                  </div>
                </div>
              </div>

              <button type="button" class="btn btn-info" @click="back">
                Back
              </button>
              &nbsp;&nbsp;
              <button type="submit" class="btn btn-primary">Next</button>
            </form>
          </div>

          <div class="row" v-if="step === 3">
            <div class="col-md-7">
              <p class="text-danger">{{ error }}</p>
              <h4 class="text-primary" v-if="total_meter_inserted">
                Number of Meter Inserted: {{ total_meter_inserted }}
              </h4>
              <table
                class="table table-borderless"
                v-if="insertedMeters.length"
              >
                <thead>
                  <tr>
                    <th>SL#</th>
                    <th class="text-center">Hardware Serial No#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, key) in insertedMeters" :key="item.id">
                    <td>{{ ++key }}</td>
                    <td class="text-center">{{ item.hardware_serial_no }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-5 text-right">
              <button type="button" class="btn btn-info" @click="back">
                Back
              </button>
              &nbsp;&nbsp;
              <div class="qr-scanner-container">
                <qrcode-stream
                  v-if="showScanner"
                  @decode="onDecode"
                  @init="onInit"
                ></qrcode-stream>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CCard>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
export default {
  name: "ScanAndStoreMeter",
  data: () => {
    return {
      meter: {
        category_id: 1,
        meter_number: "",
        batch_no: Number,
        hardware_serial_no: "",
        description: "",
        brand_name: "",
        model_number: "",
        manufacture_year: moment().format("YYYY"),
        expiry_date: new Date(),
        ip_standard_code: "",
        max_pressure: "",
        radio_frequency: "",
      },
      showScanner: false,
      serverErrors: [],
      showDismissibleAlert: false,
      error: "",
      step: 1,
      total_meter_inserted: 0,
      last_serial_no: "",
      insertedMeters: [],
      isLoading: false,
    };
  },
  components: {
    QrcodeStream,
  },
  methods: {
    addMeter() {
      try {
        this.axios
          .post(`/admin/meters`, this.meter)
          .then((res) => {
            new Audio(require("@/assets/sounds/notification.mp3")).play();
            this.total_meter_inserted += 1;
            this.insertedMeters.push(res.data.data);
            this.$toastr.s( 
              "success",
              "Successfuly Added Meter " + res.data.data.hardware_serial_no,
              "New Meter Added."
            );
          })
          .catch((err) => {
            new Audio(
              require("@/assets/sounds/glitch-in-the-matrix.ogg")
            ).play();
            this.showDismissibleAlert = true;
            this.serverErrors = [];
            if (err.response.data.message) {
              this.serverErrors.push(err.response.data.message);
            }

            let errors = err.response.data.errors;
            for (let field of Object.keys(errors)) {
              this.$toastr.e("error", errors[field][0], "Error!");
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    getNewBatchNo() {
      this.isLoading = true;
      try {
        this.axios
          .get(`/admin/meters/new-batch-no`)
          .then((res) => {
            this.meter.batch_no = res.data.data;
            this.isLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
          });
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    getMeterInfoByBatchNo() {
      this.isLoading = true;
      try {
        this.axios
          .get(`/admin/meters/batch-no/${this.meter.batch_no}`)
          .then((res) => {
            this.meter = res.data.data;
            this.meter.dev_eui = "";
            this.meter.expiry_date = new Date(this.meter.expiry_date);
            this.isLoading = false;
          })
          .catch((err) => {
            this.meter = {
              batch_no: this.meter.batch_no,
              category_id: 1,
              expiry_date: new Date(),
            };
            console.log(err);
            this.isLoading = false;
          });
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    checkStep() {
      this.step += 1;
      if (this.step === 3) {
        this.showScanner = true;
      } else if (this.step === 2) {
        this.showScanner = false;
        this.getMeterInfoByBatchNo();
      } else {
        this.showScanner = false;
      }
    },
    back() {
      this.step -= 1;
      this.showScanner = false;
    },
    onDecode(result) {
      if (result) {
        this.meter.hardware_serial_no = result;
        this.meter.meter_number = result;
        this.addMeter();
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
  },
  mounted() {
    this.getNewBatchNo();
  },
};
</script>

<style scoped lang="scss">
.qr-scanner-container {
  max-width: 300px;
  margin: 20px 15px;
  border: 5px solid white;
  background-color: #fafafa;
  .camera {
    width: 25%;
    margin: 0 auto;
  }
}
</style>

<style lang="scss">
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .card {
    border: none;
    padding: 40px 30px;
  }
}
.expire-date-picker-wrapper {
  padding: 0 5px;
  .expire-date-picker {
    width: 100%;
    height: 36px;
    border: none;
    padding-left: 5px;
    color: #495057;
    &:focus {
      outline: none;
    }
  }
}
</style>

